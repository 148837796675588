import styled from 'styled-components'
import { theme } from '@/styles/theme'

const { mediaQueryMobile, mediaQueryDesktop } = theme

export const BlogListFlex = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${mediaQueryDesktop}) {
    flex-direction: column;
  }
`

export const Container = styled.div`
  margin: 0;

  @media screen and (min-width: ${mediaQueryDesktop}) {
    margin: -15px;
    display: flex;
  }
`

export const ContentWrapper = styled.div`
  height: 100%;
  @media screen and (min-width: ${mediaQueryMobile}) {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
`

export const SideBarWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: ${mediaQueryDesktop}) {
    width: 340px;
  }
`
