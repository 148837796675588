import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'

import {
  PaginationContainer,
  PaginationWrapper,
  PaginationDirectionButton,
  PaginationDirectionButtonDisabled,
  PaginationNumberButtonWrapper,
  PaginationNumberButton,
} from './styled.js'

function Pagination({ page, total }) {
  const isFirst = page === 1
  const isLast = page === total
  const prevPage = page - 1 === 1 ? '/' : `/blog/${page - 1}`
  const nextPage = `/blog/${page + 1}`

  return (
    <PaginationContainer>
      <PaginationWrapper>
        {/* TODO: 目前採用這個方法取消 Link 的功能，未來希望可以找到好的寫法優化 */}
        {isFirst ? (
          <PaginationDirectionButtonDisabled>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </PaginationDirectionButtonDisabled>
        ) : (
          <PaginationDirectionButton to={prevPage}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </PaginationDirectionButton>
        )}

        <PaginationNumberButtonWrapper>
          {Array.from({ length: total }, (_, i) => (
            <PaginationNumberButton
              key={`pagination-number-${i + 1}`}
              to={i + 1 === 1 ? '/' : `/blog/${i + 1}`}
              active={page === i + 1}
            >
              {i + 1}
            </PaginationNumberButton>
          ))}
        </PaginationNumberButtonWrapper>

        {isLast ? (
          <PaginationDirectionButtonDisabled>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </PaginationDirectionButtonDisabled>
        ) : (
          <PaginationDirectionButton to={nextPage}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </PaginationDirectionButton>
        )}
      </PaginationWrapper>
    </PaginationContainer>
  )
}

export default Pagination
