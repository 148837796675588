import styled from 'styled-components'
import { theme } from '@/styles/theme'

export const SpotlightTag = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  border: 2px solid black;
  z-index: 5;
  background: #393e46;
  color: ${theme.spotlight};

  &:after {
    margin-left: 2px;
    content: 'Hot';
    font-size: 14px;
    font-weight: 700;
  }
`
