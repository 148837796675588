import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Header from '@/components/Header/index.jsx'
import Footer from '@/components/Footer/index.jsx'
import BackTop from '@/components/BackTop/index.jsx'
import { Container } from '@/layouts/styled'

function Layout({ children }) {
  const [active, setActive] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    function toggleNavStyle() {
      const scrollTop =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop

      const isActive = scrollTop > 30
      setActive(isActive)
    }

    document.addEventListener('scroll', toggleNavStyle)
    return () => {
      document.removeEventListener('scroll', toggleNavStyle)
    }
  }, [])

  return (
    <>
      <Header active={active} open={open} setOpen={setOpen} />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
      <BackTop />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
