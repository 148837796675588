import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Link } from 'gatsby'
import { SIDEBAR_TYPE_ENUM } from '@/common/enums/sidebar'

const {
  mainColor,
  mainColorHover,
  mainFontColor,
  mainFontSize,
  spotlight,
} = theme
const margin = '30px'
const titleFlagWidth = '4px'

export const SideBarContainer = styled.aside`
  width: 100%;
`

const barBackgroundColorAdapter = {
  [SIDEBAR_TYPE_ENUM.HOT]: spotlight,
  [SIDEBAR_TYPE_ENUM.LATEST]: mainColor,
  [SIDEBAR_TYPE_ENUM.TAG]: mainFontColor,
}

const barBackgroundColorAdapterHover = {
  [SIDEBAR_TYPE_ENUM.HOT]: spotlight,
  [SIDEBAR_TYPE_ENUM.LATEST]: mainColorHover,
  [SIDEBAR_TYPE_ENUM.TAG]: mainFontColor,
}

export const SideBarBlock = styled.div`
  h3 {
    position: relative;
    color: ${mainFontColor};
    font-size: calc(${mainFontSize} - 2px);
    padding-left: calc(20px - ${titleFlagWidth});
    margin: ${margin} auto calc(${margin} - 10px);
    font-weight: 400;
    height: 20px;
    line-height: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: ${titleFlagWidth};
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: ${props => barBackgroundColorAdapter[props.type]};
      background: linear-gradient(
          to bottom,
          ${props => barBackgroundColorAdapter[props.type]} 35%,
          ${props => barBackgroundColorAdapterHover[props.type]} 100%
        )
        left center no-repeat;
      background-size: 100%;
    }
  }
`

export const PostContainer = styled.ul`
  padding: 0;
`

export const PostItem = styled.li`
  list-style: none;

  margin-top: 5px;
`

export const PostLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: ${theme.titleFontColor};

  &:hover {
    h4 {
      color: ${theme.mainColor};
    }
  }

  .cover {
    position: relative;
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: ${props => `url(${props.img})`};
  }

  .info {
    flex: 1;
    padding: 8px 15px;
    line-height: 1.4;

    h4 {
      margin: 0;
      width: 100%;
      font-weight: 400;
      font-size: calc(${theme.mainFontSize} - 2px);
      transition: color ${theme.transitionDuration};
    }

    span {
      position: relative;
      font-size: calc(${theme.mainFontSize} - 2px);
      color: ${theme.mainFontColor};
      width: 100%;
    }
  }
`

export const TagContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`

export const TagItem = styled.li`
  list-style: none;
  margin-right: 10px;
  margin-top: 5px;
`

export const TagLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  border: 2px solid #d8e5f3;
  border-radius: 15px;
  font-size: calc(${theme.mainFontSize} - 2px);
  color: ${theme.mainFontColor};
  padding: 4px 10px;
  text-decoration: none;
  cursor: pointer;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    transition: color ${theme.transitionDuration},
      border-color ${theme.transitionDuration};

    &:hover {
      color: ${theme.mainColor};
      border-color: ${theme.mainColor};
    }
  }
`
