import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

import Spotlight from '@/components/Spotlight/index.jsx'
import { Article, CardContainer, Cover, Info, DateTag, Tags } from './styled.js'

function PostCard({ cover, date, slug, title, description, hot, tags }) {
  const { originalImg } = cover.childImageSharp.fluid
  const _date = dayjs(date).format('YYYY/MM')
  const _tags = tags.split(',').map(tag => tag.trim())
  const [render, setRender] = useState(false)
  const ref = useRef()
  const BUFFER = 100

  useEffect(() => {
    function renderEventScroll() {
      const { clientHeight } = document.documentElement
      const { top } = ref.current.getBoundingClientRect()
      if (clientHeight - BUFFER > top) {
        setRender(true)
        document.removeEventListener('scroll', renderEventScroll)
      }
    }

    document.addEventListener('scroll', renderEventScroll)
    renderEventScroll()
    return () => {
      document.removeEventListener('scroll', renderEventScroll)
    }
  }, [])

  return (
    <CardContainer
      ref={ref}
      to={`/post${slug}`}
      className={render ? 'render' : ''}
    >
      <Article>
        {hot && <Spotlight />}

        <Cover>
          <img src={originalImg} alt={title} />
        </Cover>
        <Info>
          <h2>{title}</h2>
          <p>{description}</p>
        </Info>

        <Tags>
          <FontAwesomeIcon icon={faTags} />
          <span>:</span>
          <p>{_tags.map((tag, i) => (i === 0 ? tag : ', ' + tag))}</p>
        </Tags>

        <DateTag>{_date}</DateTag>
      </Article>
    </CardContainer>
  )
}

export default PostCard
