import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { theme } from '@/styles/theme'

const shake = keyframes`
  from {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(0deg);
  }
`

export const CardContainer = styled(Link)`
  transition: opacity ${theme.transitionDuration},
    transform ${theme.transitionDuration};
  opacity: 0;
  transform: translateY(15px);

  &.render {
    opacity: 1;
    transform: translateY(0px);

    .spotlight {
      svg {
        animation: ${shake} 1500ms linear;
      }
    }
  }

  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #fff;
  color: black;
  margin-top: 15px;
  text-decoration: unset;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: 280px;
    margin: 15px;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
      transition: box-shadow 0.3s;

      .spotlight {
        svg {
          animation: ${shake} 1500ms linear;
        }
      }
    }
  }
`

export const Article = styled.article`
  transition: box-shadow 0.3s;
  cursor: pointer;
`

export const Cover = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    height: 160px;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: transparent;
    transition: background 0.3s;
  }

  & > img {
    display: block;
    width: 100%;
  }
`

export const Info = styled.div`
  width: calc(100% - 30px);
  padding: 15px 15px 50px;

  & > h2 {
    font-size: 22px;
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  & > p {
    word-break: break-all;
    font-size: 18px;
    line-height: 1.6;
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
  }
`

export const Tags = styled.div`
  position: absolute;
  bottom: 30px;
  width: 250px;
  left: 0;
  overflow-x: hidden;

  padding: 5px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  color: ${theme.mainColor};
  font-size: 16px;

  span {
    margin-left: 2px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    margin: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const DateTag = styled.time`
  display: block;
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-size: 16px;
  text-align: end;

  &:before {
    content: 'created at';
    margin-right: 5px;
  }
`
