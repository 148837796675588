import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Link } from 'gatsby'

export const PaginationContainer = styled.div`
  width: 100%;
  margin: 0;

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    margin: 30px 0 0;
  }
`

export const PaginationWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: 400px;
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    width: 600px;
  }
`

export const PaginationDirectionButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  background: ${theme.mainColor};
  border-radius: 5px;

  color: white;
  font-size: 18px;
  text-decoration: unset;
`

export const PaginationDirectionButtonDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  background: ${theme.mainFontColor};
  border-radius: 5px;

  color: white;
  font-size: 18px;
  text-decoration: unset;
`

export const PaginationNumberButton = styled(PaginationDirectionButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  background: ${props => (props.active ? theme.spotlight : theme.mainColor)};
  border-radius: 5px;

  color: white;
  font-size: 18px;
  text-decoration: unset;
`

export const PaginationNumberButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  margin: 0 auto 0 0;

  a {
    margin: 0 25px 0 0;
  }
`
