import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import dayjs from 'dayjs'

import { SIDEBAR_TYPE_ENUM } from '@/common/enums/sidebar'
import {
  SideBarBlock,
  SideBarContainer,
  PostItem,
  PostLink,
  PostContainer,
  TagContainer,
  TagItem,
  TagLink,
} from '@/components/SideBar/styled'

function SideBar() {
  const query = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              date
              hot
              tags
              cover {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const LIMIT = 6

  // Get first "LIMIT" post data.
  const latestPosts = query.allMarkdownRemark.edges.filter(
    (_, index) => index < LIMIT
  )

  // Get first "LIMIT" post witch property hot is true.
  const hotPosts = query.allMarkdownRemark.edges.reduce((ary, it) => {
    if (ary.length >= LIMIT) return ary
    if (it.node.frontmatter.hot) {
      ary.push(it)
      return ary
    }
    return ary
  }, [])

  // Get all post's tags and filter duplicates.
  const tags = query.allMarkdownRemark.edges.reduce((ary, it) => {
    const _tags = it.node.frontmatter.tags.split(',').map(it => it.trim())
    _tags.forEach(_tag => {
      if (ary.every(_it => _it !== _tag)) {
        ary.push(_tag)
      }
    })
    return ary
  }, [])

  return (
    <SideBarContainer>
      <SideBarBlock type={SIDEBAR_TYPE_ENUM.HOT}>
        <h3>熱門文章</h3>
        <PostList posts={hotPosts} type={SIDEBAR_TYPE_ENUM.HOT} />
      </SideBarBlock>

      <SideBarBlock type={SIDEBAR_TYPE_ENUM.LATEST}>
        <h3>最新文章</h3>
        <PostList posts={latestPosts} type={SIDEBAR_TYPE_ENUM.LATEST} />
      </SideBarBlock>

      <SideBarBlock type={SIDEBAR_TYPE_ENUM.TAG}>
        <h3>文章標籤</h3>
        <TagList tags={tags} />
      </SideBarBlock>
    </SideBarContainer>
  )
}

function PostList({ posts, type }) {
  return (
    <PostContainer>
      {posts.map(({ node }, i) => {
        const data = node.frontmatter
        const { originalImg } = data.cover.childImageSharp.fluid
        const formatDate = dayjs(data.date).format('YYYY-MM-DD')

        return (
          <PostItem key={`sidebar-post-${type}-${node.id}`}>
            <PostLink
              to={`/post${data.slug}`}
              title={data.title}
              img={originalImg}
            >
              <div className="cover" />
              <div className="info">
                <h4>{data.title}</h4>
                <span>{formatDate}</span>
              </div>
            </PostLink>
          </PostItem>
        )
      })}
    </PostContainer>
  )
}

function TagList({ tags }) {
  return (
    <TagContainer>
      {tags.map(tag => (
        <TagItem key={`sidebar-tag-${tag}`}>
          <TagLink to={`/search/${tag}`}>{tag}</TagLink>
        </TagItem>
      ))}
    </TagContainer>
  )
}

export default SideBar
