import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'

import { SpotlightTag } from './styled.js'

function Spotlight({ render }) {
  return (
    <SpotlightTag className="spotlight">
      <FontAwesomeIcon icon={faFire} />
    </SpotlightTag>
  )
}

export default Spotlight
