import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/layouts/HomeLayout/index.jsx'
import SEO from '@/components/seo'
import PostCard from '@/components/PostCard/index.jsx'
import SideBar from '@/components/SideBar'
import {
  BlogListFlex,
  Container,
  ContentWrapper,
  SideBarWrapper,
} from './styled.js'
import Pagination from '@/components/Pagination'

function Template({ pageContext, data }) {
  const { total, page } = pageContext
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <SEO title="自我成長、旅遊、前端工程師" />
      <BlogListFlex>
        <Container>
          <ContentWrapper>
            {edges.map(({ node }) => (
              <PostCard key={node.id} {...node.frontmatter} />
            ))}
          </ContentWrapper>
          <SideBarWrapper>
            <SideBar />
          </SideBarWrapper>
        </Container>
        <Pagination page={page} total={total} />
      </BlogListFlex>
    </Layout>
  )
}

export const query = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            description
            date
            keys
            tags
            hot
            cover {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
